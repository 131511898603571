import axios from 'axios'
// import router from '../router'

const _axios = axios.create({
  baseURL: '/cgi-bin',
  timeout: 5000
})

// 请求拦截器
_axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if(token) config.headers.Authorization = `Bearer ${token}`
    return config;
  },
  (error) => Promise.reject(error)
  )

// 响应拦截器
_axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // this.$message.error(error.response.data.message)
      window.location.href = "/";
      // this.$router.push("/uni_login")
    }
    // reject with error if response status is not 403
    return Promise.reject(error);
  }
)

function _get(_url, _params){
  _params = _params || {}
  return new Promise((resolve, reject)=>{
    _axios.get(_url, {_params})
      .then(res=>{
        if(res.status===200){
          resolve(res.data)
        }
      })
      .catch(error=>{
        reject(error)
      });
  })
}

function _post(_url, _params){
  _params = _params || {}
    let _formData = new FormData();
    Object.keys(_params).forEach((key) => {
    _formData.append(key, _params[key]);
    });    
  return new Promise((resolve, reject)=>{
    _axios.post( _url, _formData)
      // 成功
      .then(res=>{
        if(res.status === 200){
          resolve(res.data)
        }
      })
      // 失败
      .catch(error=>{
        reject(error)
      })
  })
}


export default { _get, _post }