import Vue from 'vue'
// import CountTo from 'vue-count-to'
import {
  Autocomplete, Tooltip, Loading, Badge, Button, Form, FormItem, Input, Message, Notification, MessageBox, Container, Header, Main, Aside, Footer, Menu, Submenu, MenuItemGroup, MenuItem, Breadcrumb, BreadcrumbItem,
  Card, Switch, Row, Col, Avatar, Carousel, CarouselItem, Image, Dialog, Calendar, Backtop, Icon, Tabs, TabPane, Timeline, Description, TimelineItem, Table, TableColumn, RadioButton, RadioGroup, Tag, Collapse, CollapseItem, Alert, Select, Option, Empty, Pagination, ButtonGroup, Upload,
} from 'element-ui'

Vue.use(Autocomplete)
Vue.use(Tooltip)
Vue.use(Loading)
Vue.use(Badge)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Switch)
Vue.use(Row)
Vue.use(Col)
Vue.use(Avatar)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(Dialog)
Vue.use(Calendar)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Alert)
Vue.use(Select)
Vue.use(Option)
Vue.use(Empty)
Vue.use(Pagination)
Vue.use(ButtonGroup)
Vue.use(Upload)

// Vue.use(Description)
// Vue.use(Notification)
// Vue.use(MessageBox)
// Vue.use(Message)

// 注册全局对象引用
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert