// import data from './data'
import data from './data'

export default {
  // 服务器根路径
  ROOT_PATH: '/home/xcube/Downloads/Data',
  // 服务器外部地址
  BASE_URL: 'https://www.daphina.net/Downloads/Data',
  device: {
  },
  components: [],


  // 直接路由跳转到Plankton Database
  jumpPlanktonDB(){
    window.location.href ='https://www.daphina.net/daphina' ;
  },

  // 返回：生成0-max之内的num个整型数集合
  genRandomSet(max, num){
    let R = [];
    while(R.length<num){
      let r = Math.round(Math.random()*max) ;
      if(!R.find(item=>item===r)) R.push(r);
    }
    return R;
  },

  //  在字符串指定位置插入字符串  
  // orgStr原始字符串 index指定插入位置str要插入的字符串  
  fnInsertStr(orgStr, index, str) {
    return orgStr.substring(0, index) + str + orgStr.substring(index)
  },

  // 获取文件时间戳
  getFileDateTime(fname) {
    let str = '', strNew = '', strNew1 = '', strNew2 = '';
    let { id: device_id } = JSON.parse(window.sessionStorage.getItem("device"));
    if (device_id == 31001) {
      let arr = fname.split('-');
      strNew = arr[1] + "-";
      strNew1 = this.fnInsertStr(arr[2], 2, ":");
      strNew2 = strNew + this.fnInsertStr(strNew1, 5, ":");
    }
    else {
      str = fname.replace("D-Smart-", "").replace(".jpg", "");       // 210725  00:00:08-0-63.jpg
      strNew = this.fnInsertStr(str, 6, "-");
      strNew1 = this.fnInsertStr(strNew, 9, ":");
      strNew2 = this.fnInsertStr(strNew1, 12, ":");
    }
    return strNew2;
  },

  // 格式化IPP的date_dir日期
  formateDate(date_dir) {
    return "[20" + date_dir.substr(0, 2) + "年" + date_dir.substr(2, 2) + "月" + date_dir.substr(4, 2) + "日]"
  },

  // 格式化IPP的img_list日期
  formateImgDate(date) {
    return "20" + date.substr(10, 2) + "-" + date.substr(12, 2) + "-" + date.substr(14, 2) + "T" + date.substr(16, 2) + ":" + date.substr(18, 2) + ":" + date.substr(20, 2);
  }

};