import request from '../utils/request'

// Plankton的API接口
function post(_action, _params){
  _params = _params || {};
  console.log(_params)
  return request._post('/xapi.py', {action: _action, ..._params});
}

const Login = _params => request._post('/zjapi.py',{action:'Login', ..._params})
    , GetTaxonomy = _params => request._post('/xapi.py', {action:'GetTaxonomy', ..._params})
    , SelectPicture = _params => request._post('/xapi.py', {action:'SelectPicture', ..._params})
    , SelectPictureLabel = _params => request._post('/xapi.py', {action:'SelectPictureLabel', ..._params})
    , DeletePicture = _params => request._post('/xapi.py', {action:'DeletePicture', ..._params})
    , UndeletePicture = _params => request._post('/xapi.py', {action:'UndeletePicture', ..._params})
    , InsertPicLab = _params => request._post('/xapi.py', {action:'InsertPicLab', ..._params})
    , DeletePicLab = _params => request._post('/xapi.py', {action:'DeletePicLab', ..._params})
    , UndeletePicLab = _params => request._post('/xapi.py', {action:'UndeletePicLab', ..._params})
    , InsertLabel = _params => request._post('/xapi.py', {action:'InsertLabel', ..._params})
    , DeleteLabel = _params => request._post('/xapi.py', {action:'DeleteLabel', ..._params})
    , UndeleteLabel = _params => request._post('/xapi.py', {action:'UndeleteLabel', ..._params})



export default { 
  post,
  Login,
  GetTaxonomy,
  SelectPicture,
  SelectPictureLabel,
  DeletePicture,
  UndeletePicture,
  InsertPicLab,
  DeletePicLab,
  UndeletePicLab,
  InsertLabel,
  DeleteLabel,
  UndeleteLabel,
} 
