import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Common from './Common/index'
import './plugins/element.js'
import './assets/css/global.css'


// 注册全局http API对象引用
import api from './api'
Vue.prototype.$api = api


// // 注册全局http对象引用
// Vue.prototype.$http = axios

// 注册全局变量及方法
Vue.prototype.$common = Common

// 挂载全局echarts对象
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
